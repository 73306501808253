import MoonIcon from 'assets/svg/moon.inline.svg';
import SunIcon from 'assets/svg/sun.inline.svg';
import clsx from 'clsx';
import { ThemeContext } from 'context/theme';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, memo } from 'react';

// eslint-disable-next-line import/extensions
// const loadFeatures = () => import('utils/framer-motion-features.js').then((res) => res.default);

const DarkModeButton = ({ className }) => {
  const { theme, setTheme } = useContext(ThemeContext);

  const onClickTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const iconSwitch = useMemo(
    () =>
      theme === 'light' ? (
        <MoonIcon className="absolute left-1 h-5 w-5 text-xl leading-none text-slate-500" />
      ) : (
        <SunIcon className="absolute right-1 h-5 w-5 text-xl leading-none text-amber-300" />
      ),
    [theme]
  );

  const buttonSwitch = useMemo(
    () => (
      <button
        type="button"
        className={clsx(
          'relative flex h-7 w-[53px] items-center justify-between rounded-full bg-slate-300 p-1 dark:bg-slate-700',
          className
        )}
        aria-label="Dark Mode"
        onClick={onClickTheme}
      >
        {iconSwitch}
        <div
          className={clsx(
            'absolute my-auto h-5 w-5 rounded-full bg-white text-xl shadow-md duration-300 ease-in-out',
            theme === 'light' ? 'right-1/2 -mr-[2.5px] translate-x-full' : 'translate-x-0'
          )}
        />
      </button>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [className, theme, iconSwitch]
  );

  return buttonSwitch;
};

DarkModeButton.defaultProps = {
  className: null,
};

DarkModeButton.propTypes = {
  className: PropTypes.string,
};

export default memo(DarkModeButton);
