import clsx from 'clsx';
import DarkModeButton from 'components/common/darkmode-button';
import Link from 'components/common/link';
import { SITE_CONFIG } from 'config/constants';
import useMediaQuery from 'hooks/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

const WAIT_INTERVAL = 300;
const Menu = ({ location, className }) => {
  const navItems = SITE_CONFIG.navLinks;
  const [windowWidth, breakpoints] = useMediaQuery();
  const timeoutRef = useRef();
  const containerRef = useRef(0);
  const selectedItemRef = useRef(0);
  const [open, setOpen] = useState(false);
  const [itemEl, setItemEl] = useState({ width: 0, distance: 0 });

  const calculateMenuItem = (selectedItem, container) => {
    if (selectedItem && container) {
      const width = selectedItem.offsetWidth;
      const distance = Math.abs(
        selectedItem.getBoundingClientRect().left - container.getBoundingClientRect().left
      );
      setItemEl({ width, distance });
    }
  };

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleMenuClose();
    }
  };

  useEffect(() => {
    calculateMenuItem(selectedItemRef.current, containerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useLayoutEffect(() => {
    timeoutRef.current = setTimeout(() => {
      calculateMenuItem(selectedItemRef.current, containerRef.current);
    }, WAIT_INTERVAL);
    return () => clearTimeout(timeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  const menuItems = navItems.map(({ label, url }) => (
    <li
      ref={location.pathname === url ? selectedItemRef : null}
      key={url}
      className="contain-paint group relative"
      role="presentation"
      onClick={windowWidth < breakpoints.md ? handleMenuClose : null}
      onKeyPress={handleKeyDown}
    >
      <Link to={url} className="relative z-10 block w-full px-4 py-2 text-center text-base">
        {label}
      </Link>
    </li>
  ));

  const darkModeButtonContent = (
    <li className="px-4 py-3 md:my-auto md:ml-4 md:p-0 xl:ml-6">
      <DarkModeButton className="mx-auto dark:border dark:border-slate-400 md:mx-0 md:!border-0" />
    </li>
  );

  let animItemBlockContent;
  if (windowWidth >= breakpoints.md) {
    animItemBlockContent = (
      <li
        className={clsx(
          'invisible absolute left-0 top-0 h-full transform-none rounded-full border border-slate-100 shadow-sm duration-500 ease-in-out dark:border-slate-700 md:visible',
          { hidden: itemEl.width === 0 }
        )}
        style={{ width: `${itemEl.width}px`, transform: `translate3d(${itemEl.distance}px, 0, 0)` }}
      />
    );
  }

  const closeButtonContent = (
    <li className="pb-10 text-right md:hidden md:py-0">
      <button
        type="button"
        className="py-3 text-base font-normal text-slate-800 after:ml-1 after:content-['×'] dark:text-slate-50"
        onClick={handleMenuClose}
      >
        Close
      </button>
    </li>
  );

  const menuButtonContent = (
    <button
      type="button"
      className="block p-3 text-base font-normal text-slate-800 before:mr-1 before:content-['☰'] dark:text-slate-50 md:hidden"
      onClick={handleMenuOpen}
    >
      Menu
    </button>
  );

  return (
    <nav className={clsx('flex', className)}>
      <ul
        ref={containerRef}
        className={clsx(
          'md:content-visibility fixed left-0 right-0 top-0 z-40 flex h-full transform list-none flex-col justify-start overflow-y-auto overflow-x-hidden bg-white/75 p-10 backdrop-blur backdrop-opacity-100 duration-300 ease-in-out will-change-contents dark:bg-slate-700/75 md:relative md:h-auto md:translate-x-0 md:transform-none md:flex-row md:justify-end md:overflow-visible md:!bg-transparent md:p-0',
          { 'content-visibility translate-x-0': open },
          { 'content-hidden translate-x-full': !open }
        )}
      >
        {closeButtonContent}
        {animItemBlockContent}
        {menuItems}
        {darkModeButtonContent}
      </ul>
      {menuButtonContent}
    </nav>
  );
};

Menu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: null,
};

export default Menu;
