import { useState, useEffect } from 'react';

const breakpoints = {
  xs: 639,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

const useMediaQuery = () => {
  const [windowWidth, setWindowWidth] = useState(breakpoints.xxl);

  const handleResize = () => {
    setWindowWidth(document.body.clientWidth);
  };

  useEffect(() => {
    setWindowWidth(document.body.clientWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [windowWidth, breakpoints];
};

export default useMediaQuery;
