import PropTypes from 'prop-types';
import React from 'react';

import Footer from 'components/section/footer';
import Header from 'components/section/header';
import { ThemeProvider } from 'context/theme';

const LayoutInner = ({ location, children }) => (
  <div className="flex min-h-screen flex-col gap-y-16 dark:bg-gray-800 dark:text-white">
    <Header location={location} />
    <main className="flex grow flex-col gap-y-16">{children}</main>
    <Footer />
  </div>
);

LayoutInner.propTypes = {
  children: PropTypes.node.isRequired,
};

const Layout = (props) => (
  <ThemeProvider>
    <LayoutInner {...props} />
  </ThemeProvider>
);

export default Layout;
