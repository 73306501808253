import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/common/link';

const Logo = ({ name = null, image = null, className = null }) => {
  let imageContent;
  if (image) {
    imageContent = image;
  }
  return (
    <Link className={clsx(className)} to="/">
      {imageContent}
      {name}
    </Link>
  );
};

Logo.propTypes = {
  name: PropTypes.string,
  image: PropTypes.node,
  className: PropTypes.string,
};

Logo.defaultProps = {
  name: null,
  image: null,
  className: null,
};

export default Logo;
