module.exports.SITE_CONFIG = {
  // Site info
  siteName: 'Jonas Joseph', // Site name.
  siteTitleShort: 'Jonas Blog', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Gatsby Personal Blog Template', // Alternative site title for SEO.
  siteLogo: '/assets/images/favicon.png', // Logo used for SEO and manifest.
  siteUrl:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000'
      : 'https://devcontent.andreaseracleous.com', // Domain of your website without pathPrefix.
  siteDescription: 'A GatsbyJS Blog Template for content developer.', // Website description used for RSS feeds/meta description tag.
  googleAnalyticsId: 'G-C166H5BHH7', // Google Analytics Tracking ID G-xxxxxxxxxx.
  postsPerPage: 8, // The number of posts to display on homepage
  useNewsletterForm: true, // Show Newsletter Form on Homepage
  useDefaultDarkMode: true, // Choose theme mode by default 'Light' or 'Dark'
  useDefaultSystemColorTheme: true, // Used to detect if the user has requested a light or dark color theme
  /* ========================================================================== */
  /* ========================================================================== */
  /* ========================================================================== */
  /* CONTACT FORM INTEGRATION ================================================ */
  /**
   * Recommended serverless:
   * Getform (https://www.gatsbyjs.com/docs/building-a-contact-form/#getform)
   * Formspree (https://www.gatsbyjs.com/docs/building-a-contact-form/#formspree)
   * Netlify Form (https://www.gatsbyjs.com/docs/building-a-contact-form/#netlify)
   */
  contactFormActionUrl: '/', // Add your action end-point
  /* ========================================================================== */
  /* ========================================================================== */
  /* ========================================================================== */
  /* NEWSLETTER FORM INTEGRATION ============================================== */
  /**
   * Recommended email marketing service:
   * MailerLite (https://www.mailerlite.com/help/how-to-create-an-embedded-form)
   */
  newsletterFormActionUrl:
    'https://assets.mailerlite.com/jsonp/41128/forms/54218114393441507/subscribe', // Add your action end-point
  // Example of action endpoint URL
  // https://assets.mailerlite.com/jsonp/xxxxxx/forms/xxxxxxxxxxxxxx/subscribe
  /* ========================================================================== */
  /* ========================================================================== */
  /* ========================================================================== */
  // Use for navigation
  navLinks: [
    { label: 'Home', url: '/' },
    { label: 'Blog', url: '/blog/' },
    { label: 'About', url: '/about/' },
    { label: 'Contact', url: '/contact/' },
  ],
  // Use for footer
  footerNav: [
    { label: 'Sitemap', url: '/sitemap-index.xml' },
    { label: 'RSS', url: '/rss.xml' },
    { label: 'Tags', url: '/tags' },
    { label: 'Twitter', url: 'https://twitter.com/AndreasFrontDev' },
    { label: 'LinkedIn', url: 'https://cy.linkedin.com/in/andreaseracleous' },
    { label: 'GitHub', url: 'https://github.com/AndreasEracleous' },
  ],
  social: {
    twitter: 'AndreasFrontDev', // Creates meta tags for Twitter to look your tweet richer.
  },
  authorName: '@AndreasEracleous', // Username to display in the author segment
  copyright: '| All Rights Reserved | Website designed & developed by Andreas Eracleous',
  // Use for manifest
  themeColor: '#1E293B', // Used for setting manifest and progress theme colors.
  backgroundColor: '#FFF', // Used for setting manifest background color.
};
