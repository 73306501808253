import Logo from 'components/common/logo';
import Menu from 'components/common/navigation/menu-button';
import { SITE_CONFIG } from 'config/constants';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ location }) => {
  const { siteName } = SITE_CONFIG;

  return (
    <div className="container grid-gap flex w-full items-center pt-8">
      <header className="my-auto mr-auto">
        <Logo
          className="relative z-50 flex items-center gap-x-2 text-lg tracking-wide hover:no-underline"
          name={siteName}
          image={
            <StaticImage
              className="rounded-full"
              src="../../../assets/images/simon-lohmann-0KAEhUHvcmQ-unsplash-transformed.png"
              alt={siteName}
              layout="fixed"
              width={38}
              height={38}
              loading="eager"
            />
          }
        />
      </header>
      <Menu location={location} />
    </div>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Header;
