import React from 'react';

import Link from 'components/common/link';
import { SITE_CONFIG } from 'config/constants';

const Copyright = ({ text, siteName, siteUrl }) => (
  <small className="text-md text-slate-600 dark:text-slate-400">
    © {new Date().getFullYear()}{' '}
    <Link to={siteUrl} className="h-4 p-0 text-slate-600 hover:underline dark:text-slate-400">
      {siteName}
    </Link>{' '}
    {text}
  </small>
);

const Nav = ({ items = [] }) => (
  <nav className="flex flex-wrap gap-4">
    {items.map(({ label, url }) => (
      <Link
        to={url}
        className="h-7 text-base text-slate-600 hover:underline dark:text-slate-400"
        key={url}
      >
        {label}
      </Link>
    ))}
  </nav>
);

const Footer = () => {
  const { siteName } = SITE_CONFIG;
  const { copyright } = SITE_CONFIG;
  const { siteUrl } = SITE_CONFIG;
  const navItems = SITE_CONFIG.footerNav;

  return (
    <footer className="container content-auto mb-6 mt-20 w-full">
      <Nav items={navItems} />
      <hr className="my-1 border-slate-300 dark:border-slate-700" />
      <Copyright text={copyright} siteName={siteName} siteUrl={siteUrl} />
    </footer>
  );
};

export default Footer;
